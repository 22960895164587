define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-actions", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _template, _dButton, _ajax, _ajaxError, _discourseI18n, _activityPubUtilities, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubTopicActions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "status", [_tracking.tracked]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    constructor() {
      super(...arguments);
      this.topic = _object.default.create(this.args.topic);
      this.appEvents.on("activity-pub:topic-updated", this, "topicUpdated");
      let status = "unpublished";
      if (this.topic.activity_pub_scheduled_at) {
        status = "scheduled";
      } else if (this.topic.activity_pub_published_post_count === this.topic.activity_pub_total_post_count) {
        status = "published";
      }
      this.status = status;
    }
    topicUpdated(topicId, topicProps) {
      if (this.topic.id === topicId) {
        this.topic.setProperties(topicProps);
      }
    }
    get actors() {
      return (0, _activityPubUtilities.activityPubTopicActors)(this.topic);
    }
    get actorsString() {
      return this.actors.map(actor => `<span class="activity-pub-handle">${actor.handle}</span>`).join(" ");
    }
    get publishDisabled() {
      return this.status !== "unpublished";
    }
    get publishDescription() {
      return (0, _discourseI18n.i18n)(`topic.discourse_activity_pub.publish.description.${this.status}`, {
        count: this.topic.activity_pub_total_post_count - this.topic.activity_pub_published_post_count,
        topic_id: this.topic.id,
        actors: this.actorsString
      });
    }
    publish() {
      (0, _ajax.ajax)(`/ap/topic/publish/${this.topic.id}`, {
        type: "POST"
      }).then(result => {
        if (result.success) {
          this.status = "published";
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "publish", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="activity-pub-topic-actions">
          <div class="action publish-all">
            <div class="action-button">
              <DButton
                @label="topic.discourse_activity_pub.publish.label"
                @action={{this.publish}}
                @disabled={{this.publishDisabled}}
                class="publish"
              />
            </div>
            <div class="action-description">
              {{htmlSafe this.publishDescription}}
            </div>
          </div>
        </div>
      
    */
    {
      "id": "lRNUzcpO",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-topic-actions\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"action publish-all\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"action-button\"],[12],[1,\"\\n          \"],[8,[32,0],[[24,0,\"publish\"]],[[\"@label\",\"@action\",\"@disabled\"],[\"topic.discourse_activity_pub.publish.label\",[30,0,[\"publish\"]],[30,0,[\"publishDisabled\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"action-description\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[[30,0,[\"publishDescription\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-topic-actions.js",
      "scope": () => [_dButton.default, _template.htmlSafe],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubTopicActions;
});